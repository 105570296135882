import { createStore, applyMiddleware } from 'redux'
import rootReducer from './root-reducers'
import rootSaga from './root-sagas'
import createSagaMiddleware from 'redux-saga'

const sagaMiddleware = createSagaMiddleware()
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware))
sagaMiddleware.run(rootSaga)

export default store
