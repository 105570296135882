import React from 'react'
import { Result } from 'antd'
import { useTranslation } from 'react-i18next';

function Maintain() {
  const { t } = useTranslation('common')
  return (
    <Result
      style={{ height: "100vh", display: "flex", flexDirection: "column", justifyItems: "center", justifyContent: "center" }}
      status="500"
      title={t('maintain')}
      subTitle={t('infor_maintain')}
    />
  )
}

export default Maintain
