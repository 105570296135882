export const toggleEditSettingsPopup = isOpenPopupEditSettings => ({
  type: 'TOGGLE_EDIT_SETTINGS_POPUP',
  isOpenPopupEditSettings: isOpenPopupEditSettings
})

export const setDataAdminSettings = data => ({
  type: 'SET_DATA_ADMIN_SETTINGS',
  dataSetting: data
})

export const refreshAdminSettingsList = settingsRefreshTime => ({
  type: 'REFRESH_ADMIN_SETTINGS_LIST',
  settingsRefreshTime: settingsRefreshTime
})

export const setEditSettingsRange = data => ({
  type: 'SET_EDIT_SETTINGS_RANGE',
  idsSettingsRange: data
})

export const setIsAddSettingsRange = boolean => ({
  type: 'SET_IS_ADD_SETTINGS_RANGE',
  isAddSettingsRange: boolean
})

export const isTriggerResize = boolean => ({
  type: 'TRIGGER_RESIZE_CHART',
  isResize: boolean
})
