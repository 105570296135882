const adminReducer = (state = {}, action) => {
  switch (action.type) {
    case 'TOGGLE_EDIT_SETTINGS_POPUP':
      return {
        ...state,
        isOpenPopupEditSettings: action.isOpenPopupEditSettings
      }
    case 'SET_DATA_ADMIN_SETTINGS':
      return {
        ...state,
        dataSetting: action.dataSetting
      }
    case 'REFRESH_ADMIN_SETTINGS_LIST':
      return {
        ...state,
        settingsRefreshTime: action.settingsRefreshTime
      }
    case 'SET_EDIT_SETTINGS_RANGE':
      return {
        ...state,
        idsSettingsRange: action.idsSettingsRange // array
      }
    case 'SET_IS_ADD_SETTINGS_RANGE':
      return {
        ...state,
        isAddSettingsRange: action.isAddSettingsRange // true/false
      }
    case 'TRIGGER_RESIZE_CHART':
      return {
        ...state,
        isResize: action.isResize // true/false
      }
    default:
      return state
  }
}

export default adminReducer
