const keyReducer = (state = {}, action) => {
  switch (action.type) {
    case 'TOGGLE_CREATE_KEY_POPUP':
      return {
        ...state,
        isShowCreateKeyPopup: action.isShowCreateKeyPopup
      }
    case 'SET_DATA_KEY_WHEN_EDIT':
      return {
        ...state,
        dataKey: action.dataKey
      }
    case 'REFRESH_LIST_KEYS':
      return {
        ...state,
        refreshTime: action.refreshTime
      }
    case 'TOGGLE_VERIFY_CODE_2FA_POPUP':
      return {
        ...state,
        isShowVerifyCode2FaPopup: action.isShowVerifyCode2FaPopup,
        dataAfterVerify: action.dataAfterVerify
      }
    default:
      return state
  }
}

export default keyReducer
