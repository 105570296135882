import { combineReducers } from 'redux'
import userReducer from './reducers/user-reducer'
import keyReducer from './reducers/key-reducer'
import adminReducer from './reducers/admin-reducer'
import paginationReducer from './reducers/pagination-reducer'

export default combineReducers({
  userAction: userReducer,
  keyAction: keyReducer,
  adminAction: adminReducer,
  paginationAction: paginationReducer
})
