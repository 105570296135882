const paginationReducer = (state = {}, action) => {
  switch (action.type) {
    case 'PAGINATION':
      return {
        ...state,
        isPageSaleAcc: action.isPageSaleAcc
      }
    case 'USERMANAGEMENT':
      return {
        ...state,
        isPageUserManagement: action.isPageUserManagement
      }
    case 'BILLMANAGEMENT':
      return {
        ...state,
        isPageBillManagement: action.isPageBillManagement
      }
    case 'KEYTABLE':
      return {
        ...state,
        isPageKey: action.isPageKey
      }
    case 'BILLTABLE':
      return {
        ...state,
        isPageBill: action.isPageBill
      }
    case 'PAYMENTTABLE':
      return {
        ...state,
        isPagePayment: action.isPagePayment
      }
    default:
      return state
  }
}

export default paginationReducer
